<template>
    <page-title
        title="応援カメラマン一覧"
        icon="bi-people-fill"
    >
        <router-link :to="{name: 'PartnerAdd'}" class="btn btn-outline-primary">
            <i class="bi bi-plus-lg"></i> 新規登録
        </router-link>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>氏名</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group col-md-4">
                <label>ランク</label>
                <select class="form-select" required>
                    <option>-- 全て --</option>
                    <option>プレミアム</option>
                    <option>準レギュラー</option>
                    <option>育成</option>
                    <option>人気スポット</option>
                    <option>スポット</option>
                    <option>戦力外</option>
                </select>
            </div>
            <div class="col-3">
                <button class="btn btn-outline-primary"><i class="bi bi-search"></i> 検索</button>
            </div>
        </div>
    </section>

    <section class="section">
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="text-center">氏名</th>
                    <th class="text-center">略称</th>
                    <th class="text-center">電話番号</th>
                    <th class="text-center">ランク</th>
                    <th class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">鈴木一郎</td>
                    <td class="text-center">鈴</td>
                    <td class="text-center">06-1111-2222</td>
                    <td class="text-center">プレミアム</td>
                    <td class="text-center">
                        <router-link :to="{name: 'PartnerEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">佐藤次郎</td>
                    <td class="text-center">佐</td>
                    <td class="text-center">06-1111-2222</td>
                    <td class="text-center">プレミアム</td>
                    <td class="text-center">
                        <router-link :to="{name: 'PartnerEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">北村三郎</td>
                    <td class="text-center">北</td>
                    <td class="text-center">06-1111-2222</td>
                    <td class="text-center">準レギュラー</td>
                    <td class="text-center">
                        <router-link :to="{name: 'PartnerEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">伊藤四郎</td>
                    <td class="text-center">伊</td>
                    <td class="text-center">06-1111-2222</td>
                    <td class="text-center">育成</td>
                    <td class="text-center">
                        <router-link :to="{name: 'PartnerEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">野口五郎</td>
                    <td class="text-center">野</td>
                    <td class="text-center">06-1111-2222</td>
                    <td class="text-center">人気スポット</td>
                    <td class="text-center">
                        <router-link :to="{name: 'PartnerEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>

            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
            </nav>
        </div>

    </section>

</template>

<script>
import PageTitle from '@/components/PageTitle';


export default {
    name: 'PartnerList',
    data() {
        return {

        }
    },
    components: {
        PageTitle,
    }
}
</script>

<style scoped>

</style>
